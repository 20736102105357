import React from 'react';
import styled from "styled-components";
import { between } from 'styled-breakpoints';

const Root = styled.div`
 background-image: url('/images/Explose_soldier.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100vw;
  height: 85vh;

  ${between('mini', 'mobile')} {
    height: 20vh;
  }
`
const Explose = () => (
  <Root />
);

export default Explose;
