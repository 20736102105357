import React from 'react';
import styled from "styled-components";
import { Text, MiddleLayout } from 'src/components/Common';
import { COLORS } from 'src/style/colors';

const Root = styled.div`
  margin-top: 60px;
`;

const Content = styled.div`
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url('/images/rectungle_our_mission.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100vw;
  height: 53vh;
`;

const OurVision = () => (
  <Root>
    <MiddleLayout center>
      <Text color={COLORS.PRIMARY} variant='h2' text="Our vision as A-Team" isBold />
    </MiddleLayout>
    <Content>
      <MiddleLayout center>
        <Text color={COLORS.WHITE} text="Our vision description" variant="h6" component="h2" />
      </MiddleLayout>
    </Content>
  </Root>
);

export default OurVision;
