import React from 'react';
import styled from "styled-components";
import { Text } from 'src/components/Common';
import { COLORS } from 'src/style/colors';
import { only, between, up } from 'styled-breakpoints';
import useMediaQuery from "../../style/useMediaQuery";

const Root = styled.div`
  position: relative;
`;

const Image = styled.div`
  background-image: url('/images/about_banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 94vh;
  min-height: 680px;
  ${between('mini', 'mobile')} {
    display: none;
    width: 0;
    height: 0;
  };
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  left: 5vw;
  top: 7vh;
  gap: 25px;

  ${up('laptop')} {
    position: absolute;
    max-width: 600px;
  };

  ${only('laptop')} {
    left: 100px;
    max-width: 450px;
  };

  ${only('desktop')} {
    left: 4vw;
    max-width: 500px;
  };
`;

const Header = styled.div`
  ${between('mini', 'mobile')} {
    background: var(--linear-secondary);
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 15px;
  }
`;

const TopSection = () => {
  const mediaQuery = useMediaQuery();
  return (
    <Root>
      <Image />
      <Content>
        <Header>
          <Text color={mediaQuery.isLaptopOrAbove ? COLORS.SECONDARY : COLORS.WHITE} variant='h2' text="We are Appsforces" component="h1" isBold />
          <Text color={mediaQuery.isMobileOrMini ? COLORS.WHITE : COLORS.BLACK} text="About description" variant="h6" component="h2" />
        </Header>
      </Content>
    </Root>
  );
}

export default TopSection;
