import React from 'react';
import styled from 'styled-components';
import { Text, MiddleLayout, Link } from 'src/components/Common';
import { COLORS } from 'src/style/colors';
import Worker from './Worker';
import useMediaQuery from 'src/style/useMediaQuery';
import { between } from 'styled-breakpoints';

const FOUNDERS = [
  {
    name: 'Amit Markovich',
    title: 'CO-Founder & CEO',
    image: '/images/workers/amit.jpeg',
    url: 'https://www.linkedin.com/in/amitmarkovich/',
  },
  {
    name: 'Ran Endelman',
    title: 'CO-Founder & CEO',
    image: '/images/workers/ran.jpeg',
    url: 'https://www.linkedin.com/in/ranendelman/',
  },
];

const DEVELOPERS = [
  {
    name: 'Hen Aharon',
    title: 'Tech Lead',
    image: '/images/workers/hen_aharon.png',
  },
  {
    name: 'Avital Tchernoguz',
    title: 'Tech Lead',
    image: '/images/workers/avital.jpeg',
  },
  {
    name: 'Yarin Shabi',
    title: 'Recruitment Specialist & HR',
    image: '/images/workers/yarin.jpeg',
  },
  {
    name: 'Itai Rom',
    title: 'Software Developer',
    image: '/images/workers/itai_rom.jpeg',
  },
  {
    name: 'Michael Donchenko',
    title: 'Software Developer',
    image: '/images/workers/michael_donchenko.jpeg',
  },
  {
    name: 'Andrey Burtsev',
    title: 'Software Developer',
    image: '/images/workers/andrey_burtsev.jpg',
  },
  {
    name: 'Elad Sarusi',
    title: 'Software Developer',
    image: '/images/workers/elad_sarusi.png',
  },
  {
    name: 'Maxim Feldman',
    title: 'Software Developer',
    image: '/images/workers/maxim_feldman.jpg',
  },
  {
    name: 'Rom Waidergoren',
    title: 'Software Developer',
    image: '/images/workers/rom.jpeg',
  },
  {
    name: 'Dan Bar Natan',
    title: 'Mobile Developer',
    image: '/images/workers/dan_bar_natan.jpeg',
  },
  {
    name: 'Efraim Alkhazov',
    title: 'Mobile Developer',
    image: '/images/workers/efi.jpeg',
  },
  {
    name: 'Matan Cohen',
    title: 'Senior Software Developer',
    image: '/images/workers/matan.jpeg',
  },
  {
    name: 'Yuval Itah',
    title: 'Software Developer',
    image: '/images/workers/yuval.jpeg',
  },
  {
    name: 'Yuval Cohen',
    title: 'Software Developer',
    image: '/images/workers/yuval_cohen.jpeg',
  },
  {
    name: 'Maayan Cohen',
    title: 'Software Developer',
    image: '/images/workers/maayan_cohen.png',
  },
  {
    name: 'Michael Ivlev',
    title: 'Mobile Developer',
    image: '/images/workers/micha.png',
  },
  {
    name: 'Lior Rachmani',
    title: 'Software Developer',
    image: '/images/workers/lior.jpeg',
  },
  {
    name: 'Nadav Pevzner',
    title: 'Software Developer',
    image: '/images/workers/nadav.png',
  },
];

const Root = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const CarouselWrapper = styled.div`
  padding: 40px 10px;
  max-width: 105vw;
  display: none;

  ${between('mini', 'mobile')} {
    display: block;
    --swiper-navigation-sides-offset: 30%;
    --swiper-navigation-top-offset: 90%;
  }
`;

const List = styled.ul`
  margin: 50px 0 100px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  max-width: 1200px;

  ${between('mini', 'mobile')} {
    display: none;
  }
`;

const FoundersList = styled.ul`
  margin: 100px 0 0px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;

  ${between('mini', 'mobile')} {
    gap: 35px;
  }
`;

const Founder = styled.li`
  cursor: pointer;
`;

const WorkerWrapper = styled.div`
  padding-bottom: 90px;
`;

const WorkersList = () => {
  const mediaQuery = useMediaQuery();
  return (
    <Root>
      <MiddleLayout center>
        <Text
          color={COLORS.PRIMARY}
          variant="h2"
          text="The faces behind Appsforces"
          isBold
        />
      </MiddleLayout>
      <MiddleLayout center>
        <FoundersList>
          {FOUNDERS.map((worker) => (
            <Founder>
              <Link external to={worker.url}>
                <Worker {...worker} />
              </Link>
            </Founder>
          ))}
        </FoundersList>
        <CarouselWrapper key={mediaQuery.isMini}>
          <swiper-container
            slides-per-view={mediaQuery.isMini ? '2' : '3'}
            loop="true"
            css-mode="true"
            navigation="true"
          >
            {DEVELOPERS.map((worker) => (
              <swiper-slide>
                <WorkerWrapper>
                  <Worker {...worker} />
                </WorkerWrapper>
              </swiper-slide>
            ))}
          </swiper-container>
        </CarouselWrapper>
        <List>
          {DEVELOPERS.map((worker) => (
            <li>
              <Worker {...worker} />
            </li>
          ))}
        </List>
      </MiddleLayout>
    </Root>
  );
};

export default WorkersList;
