import React from 'react';
import TopSection from './TopSection';
import WorkersList from './WorkersList';
import OurVision from './OurVision';
import Explose from './Explose';
import { SEO } from 'src/components/Common';
import { useLocation } from "@reach/router";
import { useTranslation } from "gatsby-plugin-react-i18next";

const About = () => {
    const { href } = useLocation();
    const { t } = useTranslation();
    const breadcrumbsElementsSchema = [
      {
        "@type": "ListItem",
        position: 2,
        name: t("About"),
        item: href,
      },
    ];
    return (
      <div>
          <SEO
            title="SEO title about"
            description="SEO description about"
            breadcrumbsElementsSchema={breadcrumbsElementsSchema}
          />
          <TopSection />
          <WorkersList />
          <OurVision />
          <Explose />
      </div>
    );
}

export default About;
