import React from 'react';
import styled from 'styled-components';
import { Text } from 'src/components/Common';
import { COLORS } from 'src/style/colors';
import { between } from 'styled-breakpoints';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 230px;

  ${between('mini', 'mobile')} {
    width: auto;
  }
`;

const ImageWrapper = styled.div`
  display: block;
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const Image = styled.img`
  height: 150px;
  width: 150px;
  border: 2px solid var(--secondary) !important;
  border-radius: 50%;
`;

const Worker = ({ image, name, title }) => (
  <Root>
    <ImageWrapper>
      <Image src={image} alt="worker" />
    </ImageWrapper>
    <Text text={name} variant="h6" isBold />
    <Text color={COLORS.GRAY} text={title} variant="body1" component="p" />
  </Root>
);

export default Worker;
